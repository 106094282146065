import { useState, useEffect, useRef } from 'react'

export default function useElipsis() {
  let [elipsis, setElipsis] = useState('')
  // TODO: Surely there's a better way to do this?
  // TODO: setTimeout instead (?)
  let ref = useRef(elipsis)

  useEffect(() => {
    const interval = setInterval(() => {
      // 3 states
      ref.current = '.'.repeat((ref.current.length % 3) + 1)
      // or 4 states
      // ref.current = '.'.repeat((ref.current.length + 1) % 3)
      setElipsis(ref.current)
    }, 650)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return elipsis
}
