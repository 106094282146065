import React from 'react'
import Helmet from 'react-helmet'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html, body {
    background: #1a1a1a;
    height: 100%;
    margin: 0;
    overflow: hidden;
    width: 100%;
  }
`
const title = 'Where is Tony?'
// const published_time = new Date().toISOString().substring(0, 10)
// let tomorrow = new Date()
// tomorrow.setDate(tomorrow.getDate() + 1)
// const expiration_time = tomorrow.toISOString().substring(0, 10)

const Layout = ({ children }) => (
  <React.Fragment>
    <GlobalStyle />
    <Helmet>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" type="image/png" href="/icon.png" />
      <meta property="og:url" content="https://whereistony.wande.rs" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content="/og.jpg" />
      <link rel="manifest" href="manifest.webmanifest" />
      {/* <meta property="og:article:published_time" content={published_time} />
      <meta property="og:article:expiration_time" content={expiration_time} /> */}
    </Helmet>
    {children}
  </React.Fragment>
)

export default Layout
