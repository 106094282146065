import React from 'react'
import WhereIs from '../components/WhereIs'
import Layout from '../components/Layout'

const IndexPage = () => (
  <Layout>
    <WhereIs />
  </Layout>
)

export default IndexPage
