import { useState, useEffect } from 'react'
import fetchPlace from '../fetch'

export default function usePlace() {
  const [state, setState] = useState({ place: null, fetched: false })
  useEffect(() => {
    fetchPlace().then(place => setState({ place, fetched: true }))
  }, [])

  return state
}
