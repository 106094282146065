import React from 'react'
import SizedText from './SizedText'
import VelocityTransitionGroup from 'velocity-react/velocity-transition-group'

export default function Disclaimer({ text }) {
  const style = {
    bottom: '10px',
    fontStyle: 'italic',
    left: '10px',
    position: 'absolute',
    textAlign: 'left'
  }
  return (
    <VelocityTransitionGroup
      enter={{ animation: 'fadeIn', duration: 1000, delay: 2000 }}
    >
      {text && (
        <SizedText key="disclaimer" style={style}>
          {text}
        </SizedText>
      )}
    </VelocityTransitionGroup>
  )
}
