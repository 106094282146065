import { firebase } from '@firebase/app'
import '@firebase/firestore'

firebase.initializeApp({
  apiKey: 'AIzaSyC9fxHH317H6FHiFPzTNWxZKxibRj4wSsA',
  databaseURL: 'https://schengen-e8dd4.firebaseio.com',
  projectId: 'schengen-e8dd4'
})

export default async function fetchPlace() {
  const firestore = firebase.firestore()
  firestore.settings({ timestampsInSnapshots: true })
  const doc = await firestore
    .collection('share')
    .doc('0d0yLtKskzP2XUaBXIbh8ywprVl2')
    .get()
  const visits = doc.exists ? doc.data().visits : []

  // TODO: Had to reimplement getTodaysVisit here :/
  let name = null
  const today = new Date().toISOString().substring(0, 10)
  for (let i = 0; i < visits.length; i++) {
    const visit = visits[i]
    if (visit.start <= today && visit.end >= today) {
      name = visit.place.name
      // include initial✈final on travel days
      if (
        visit.end === today &&
        visits[i + 1] &&
        visits[i + 1].start === today
      ) {
        name += ` \uFE0E✈ ${visits[i + 1].place.name}`
      }
      break
    }
  }
  return name
}
