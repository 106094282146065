import React from 'react'

import useElipsis from '../hooks/elipsis'
import usePlace from '../hooks/place'

import VelocityTransitionGroup from 'velocity-react/velocity-transition-group'
import VelocityComponent from 'velocity-react/velocity-component'

import Disclaimer from './Disclaimer'
import SizedText from './SizedText'

// Short circuit rendering on "server" since there's no window to size
const isServer = typeof window === 'undefined'

const style = {
  alignItems: 'center',
  color: '#f0f0f0',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: '"Lucida Console", Monaco, monospace',
  justifyContent: 'center',
  overflow: 'hidden',
  position: 'absolute',
  textAlign: 'center',
  whiteSpace: 'pre',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
}

const fadeInDuration = 1500
const fadeInDelay = 500

function WhereIs() {
  const elipsis = useElipsis()

  const { place = `Hm, can't find him!`, fetched } = usePlace()
  const disclaimer =
    fetched && `* This is firmly tongue-in-cheek, please continue to say hi :)`

  const isWarsaw = place === 'Warszawa'
  const warsaw = '...zawsze Warszawa'
  const warsawOpacity = isWarsaw ? 0.7 : 0

  return isServer ? null : (
    <div style={style}>
      <VelocityTransitionGroup
        enter={
          fetched && {
            animation: 'fadeIn',
            duration: fadeInDuration,
            delay: fadeInDelay
          }
        }
        leave={{ animation: 'fadeOut', duration: fadeInDelay - 100 }}
      >
        {fetched ? (
          <SizedText key="place">{place}</SizedText>
        ) : (
          <SizedText key="loading" scale={0.25}>
            {elipsis.padEnd(3)}
          </SizedText>
        )}
      </VelocityTransitionGroup>
      <VelocityComponent
        animation={{ opacity: warsawOpacity }}
        duration={1000}
        delay={2000}
      >
        <SizedText scale={0.5}>{warsaw}</SizedText>
      </VelocityComponent>
      <Disclaimer text={disclaimer} />
    </div>
  )
}

export default WhereIs
